import styled from 'styled-components';

import { Color, ThemeProps } from '@foodles/ui-components/theme';

interface StyledLinkProps extends ThemeProps {
  color: Color;
}

export const StyledLink = styled.a<StyledLinkProps>`
  color: ${({ theme, color }: StyledLinkProps): string => theme.color[color]};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
